import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/customartist/report`

const loadArtistArtworks = (args) => {
  return axios.post(api + '/loadArtistArtworks', args)
}

export default {
  loadArtistArtworks,
}
