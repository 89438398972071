<template>
  <v-main class="hero">
    <v-container>
      <v-row v-if="!isLoggedIn">
        <v-col sm="12" md="4">
          <v-card class="fill-height d-flex flex-column" flat max-width="1024">
            <v-toolbar class="flex-grow-0"  color="primary" dark>
              <v-toolbar-title>For Retailers</v-toolbar-title>
            </v-toolbar>
            <v-card-title class="text-h5">
              Apply for a Trade Account
            </v-card-title>
            <v-card-text>
              We supply many types of retail outlets, including visitor attractions, gift shops, framers, etc.
            </v-card-text>
            <v-card-text>
              Trade customers enjoy great personal service and generous discounted prices. Click 'Register' to get started
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions class="justify-center">
              <v-btn color="primary" to="/login">
                Login
              </v-btn>
              <v-btn color="primary" to="/register">
                Register
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col sm="12" md="4">
          <v-card class="fill-height d-flex flex-column" flat max-width="1024">
            <v-toolbar class="flex-grow-0" color="primary" dark>
              <v-toolbar-title>For Artists</v-toolbar-title>
            </v-toolbar>
            <v-card-title class="text-h5">
              Self Publishing
            </v-card-title>
            <v-card-text>
                If you are an artist and want to create products or fine art prints from your work
                we are your perfect partner
            </v-card-text>
            <v-card-text>
                We have a dedicated artists site with many resources for creating products from your artwork
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions class="justify-center" >
              <v-btn color="primary"
                href="https://yourdoricmor.com/product-homepage/">
                Artists Site
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col sm="12" md="4">
          <v-card class="fill-height d-flex flex-column" flat max-width="1024">
            <v-toolbar class="flex-grow-0" color="primary" dark>
              <v-toolbar-title>Fine Art + Merchandise</v-toolbar-title>
            </v-toolbar>
            <v-card-title class="text-h5">
              Explore our range
            </v-card-title>
            <v-card-text>
              We work with some of the best contemporary Scottish artists to bring you their work as both fine art
              prints and quality merchandise
            </v-card-text>
            <v-card-text>
              We don't sell directly to the public, but if you are interested in any of our products, please check out our retailers
            </v-card-text>
            <v-spacer></v-spacer>
              <v-card-actions class="justify-center">
                <v-btn color="primary" to="/artists">
                  Artists
                </v-btn>
                <v-btn color="primary" to="/retailers">
                  Retailers
                </v-btn>
              </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <dashboard v-if="isLoggedIn"></dashboard>
    </v-container>
  </v-main>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Dashboard from './Common/Dashboard'
export default {
  name: 'Doricmor',
  components: {
    Dashboard
  },
  created() {
  },
  methods: {
    goToProducts(category) {
      const obj = {}
      obj.category = [category]
      this.$store.commit('filters/setFilters', obj)
      this.$router.push('/products').catch((err) => {
        return err !== null
      })
    }
  },
  computed: mapState({
    ...mapGetters([
      'isLoggedIn'
    ])
  })
}
</script>
<style scoped lang="scss">
.v-card__actions {
  border-top: 1px solid silver;
}
.hero {
  background: url('../assets/bg-014-green.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.25);
}
</style>
